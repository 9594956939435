<h6>
  {{ title }}

  <mat-icon
    class="float-right cursor-pointer text-medium-emphasis"
    (click)="emitCloseDialog()"
  >
    close
  </mat-icon>
</h6>
