export enum Keyboard {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  DASH = '-',
  DOT = '.',
  EMPTY = '',
  COMMA = ',',
  CONTROL = 'Control',
  ENTER = 'Enter',
}
