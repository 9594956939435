<div class="flex flex-row items-center gap-2">
  <a
    [href]="url"
    (click)="navigate($event)"
    class="text-link hover:underline"
    >{{ itemId }}</a
  >
  @if (isRfq) {
    <img
      class="pt-[2px]"
      [ngSrc]="imagePath"
      width="24"
      height="24"
      priority
      [matTooltip]="toolTipText"
    />
  }
</div>
