@if (filterName) {
  <mat-form-field
    #formField
    class="w-full"
    appearance="outline"
    *transloco="let t; read: 'shared.caseMaterial.addEntry'"
    [ngClass]="{ 'error-quotation': !isValid }"
  >
    <mat-label class="error-quotation-label">{{
      t(filterName + '.label')
    }}</mat-label>
    <input
      [formControl]="searchFormControl"
      (paste)="onPaste($event)"
      matInput
      #valueInput
      [matAutocomplete]="auto"
      cdkFocusInitial
      pinDropDown="matAutocompleteTrigger"
    />
    @if (autocompleteLoading) {
      <mat-spinner matSuffix [diameter]="18"></mat-spinner>
    }
    <mat-hint>{{ t(filterName + '.hint') }}</mat-hint>
    @if (searchFormControl.hasError('invalidInput')) {
      <mat-error>{{ t(filterName + '.wrongInput') }}</mat-error>
    }
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      @for (idValue of unselectedOptions; track $index) {
        <mat-option [value]="idValue">
          <span> {{ idValue.id }} | {{ idValue.value }}</span>
          @if (idValue.value2) {
            <span> | {{ idValue.value2 }}</span>
          }
        </mat-option>
      }
      @if (
        unselectedOptions
          | noResultsFound
            : selectedIdValue
            : searchFormControl.value
            : autocompleteLoading
            : debounceIsActive
      ) {
        <mat-option disabled>
          {{ t(filterName + '.nothingFound') }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
